<template>
  <div class="see">
    <el-dialog
      title="发帖"
      :visible.sync="dialogVisible"
      width="1000px"
      :show-close="false"
      :before-close="handleClose"
    >
      <div class="see_cont">
        <el-row>
          <el-col :span="24"
            >主题：<el-input
              type="text"
              placeholder="请输入描述"
              v-model="title"
              style="width: 90%"
            >
            </el-input
          ></el-col>
          <!-- <el-col :span="7">版块： <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in row"
                :key="item.Id"
                :label="item.SectionName"
                :value="item.Id">
              </el-option>
            </el-select></el-col> -->
        </el-row>
        <el-row>
          <el-col :span="21" style="margin-top: 8px">
            <div
              v-html="comment"
              class="comment"
              style="max-height: 200px; overflow: auto"
            ></div>
          </el-col>
        </el-row>
      </div>

      <div class="upload">
        <div class="upload_cont">
          <div class="edit_container">
            <quill-editor
              v-model="content"
              ref="myQuillEditor"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @change="onEditorChange($event)"
            >
            </quill-editor>
          </div>
        </div>
        <el-upload
          v-show="false"
          class="upload-demo uploadFile"
          action="str"
          :http-request="postImagesUploadPic"
        >
          <el-button size="small" type="primary">上传文件</el-button>
        </el-upload>
      </div>

      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="seeDefine()" class="download">下 载</el-button> -->
        <el-button @click="seeDefine()" class="upload" type="primary"
          >发布</el-button
        >
        <el-button @click="handleClose()">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import { imagesUploadPic } from "@/api/api";
import { saveTopic } from "@/api/bbs";
import { quillEditor, Quill } from "vue-quill-editor"; //调用编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
// 自定义插入a链接
var Link = Quill.import("formats/link");
class FileBlot extends Link {
  // 继承Link Blot
  static create (value) {
    let node = undefined;
    if (value && !value.href) {
      // 适应原本的Link Blot
      node = super.create(value);
    } else {
      // 自定义Link Blot
      node = super.create(value.href);
      // node.setAttribute('download', value.innerText);  // 左键点击即下载
      node.innerText = value.innerText;
      node.download = value.innerText;
    }
    return node;
  }
}
FileBlot.blotName = "link";
FileBlot.tagName = "A";
Quill.register(FileBlot);
// 编辑器工具栏配置
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
  // ['blockquote', 'code-block'],     //引用，代码块
  [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
  [{ list: "ordered" }, { list: "bullet" }], //列表
  // [{ 'script': 'sub'}, { 'script': 'super' }],   // 上下标
  [{ indent: "-1" }, { indent: "+1" }], // 缩进
  // [{ 'direction': 'rtl' }],             // 文本方向
  // [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
  // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
  // [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
  // [{ 'font': [] }],     //字体
  [{ align: [] }], //对齐方式
  ["link", "image", "upload"], //上传图片、上传视频
  ["clean"], //清除字体样式
];
let self = this;
let state = 0;
export default {
  props: ["dialogVisible", "row", "sectionId"],
  data () {
    return {
      imagesUploadPic1: "",
      imgHeaders: { "Content-Type": "multipart/form-data" },
      imagesUpload: "",
      uploadName: "",
      describe: "",
      editorOption: {
        placeholder: "请在这里输入您的发帖内容",
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: function (value) {
                //编辑器-上传图片
                if (value) {
                  state = 1;
                  document.activeElement.blur();
                  document.querySelector(".uploadFile input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
              upload: (value) => {
                //编辑器-上传文件
                if (value) {
                  state = 2;
                  document.activeElement.blur();
                  document.querySelector(".uploadFile input").click();
                }
              },
            },
          },
        },
      },
      content: "",
      comment: "",
      title: "",
      value: 1,
      user: {}
    };
  },
  components: {
    quillEditor,
  },
  computed: {
    editor () {
      return this.$refs.myQuillEditor.quill;
    },
  },
  created () {
    if (localStorage.getItem("userInfo") != null) {
      this.user = JSON.parse(localStorage.getItem("userInfo"));
    } else {
      this.user.Id = 0;
    }
  },
  mounted () {
    //图片上传地址
    console.log(this.row);
  },
  methods: {
    // 上传文件
    async postImagesUploadPic (param) {
      const formData = new FormData();
      formData.append("file", param.file);
      const res = await imagesUploadPic(formData);
      if (res.success == true) {
        let quill = this.$refs.myQuillEditor.quill;
        let length = quill.getSelection().index;
        console.log(state, "");
        if (state == 1) {
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(
            length,
            "image",
            this.GLOBAL.hostUrl + res.response
          );
        } else if (state == 2) {
          // 插入文件，res为服务器返回的文件链接地址
          quill.insertEmbed(length, "link", {
            href: this.GLOBAL.hostUrl + res.response,
            innerText: param.file.name,
          });
        }
        // 调整光标到最后
        quill.setSelection(length + 1);
        // console.log(res.response, '')
        console.log(this.content, "");
      } else {
        this.$message.error(res.msg);
      }
    },
    onEditorReady (editor) {
      // 准备编辑器
    },
    onEditorBlur () { }, // 失去焦点事件
    onEditorFocus () { }, // 获得焦点事件
    onEditorChange () {
      console.log(this.content, "");
    }, // 内容改变事件
    // 转码
    escapeStringHTML (str) {
      str = str.replace(/&lt;/g, "<");
      str = str.replace(/&gt;/g, ">");
      return str;
    },
    handleClose () {
      this.$emit("close");
    },
    async seeDefine () {
      if (this.title == "") {
        this.$message.info("请输入发帖主题");
        return false;
      }
      if (this.content != "") {
        let parm = {
          SectionId: this.sectionId,//this.value,
          CreateUserId: this.user.Id,
          TopicTitle: this.title,
          TopicContent: this.content,
        };
        const res = await saveTopic(parm);
        if (res.success == true) {
          // this.$message.success(res.msg);
          this.$message.success('发布成功');
          this.$emit("close");
          this.$emit("finishadd");
          // this.$parent.init();
        } else {
          this.$message.error(res.msg);
        }
      } else {
        this.$message.info("请输入发帖内容");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.see {
  /deep/.el-dialog {
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0 0;
      background-color: #1288f4;
      // padding: 0;
      .el-dialog__title {
        color: #ffffff;
        // height: 60px;
        // line-height: 60px;
        // padding: 0 30px;
      }
    }
    .see_cont {
      line-height: 40px;
      .el-row {
        margin-bottom: 10px;
        font-size: 14px;
        .content {
          /deep/a {
            color: #1288f4;
          }
          /deep/img {
            width: 100%;
          }
        }
      }
    }
    .dialog-footer {
      .el-button {
        // font-size: 20px;
      }
      .download {
        background-color: #28b28b;
        color: #ffffff;
      }
    }
  }
}

/deep/.ql-editor {
  min-height: 200px !important;
}
.upload {
  /deep/.el-dialog {
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0 0;
      background-color: #1288f4;
      // padding: 0;
      .el-dialog__title {
        color: #ffffff;
        // height: 60px;
        // line-height: 60px;
        // padding: 0 30px;
      }
    }
    .upload_cont {
      // line-height: 40px;
      .el-row {
        margin-bottom: 10px;
        font-size: 14px;
        .el-col {
          &:nth-child(1) {
            text-align: right;
          }
        }
        .up_flex {
          display: flex;
          align-items: center;
          line-height: 38px;
          .el-input {
            .el-input__inner {
              border-radius: 4px 0px 0px 4px;
            }
          }
          .el-button {
            height: 40px;
            border-radius: 0px 4px 4px 0px;
            background-color: #1288f4;
          }
        }
      }
      /deep/.ql-editor,
      .ql-container {
        min-height: 200px !important;
      }
    }
    .dialog-footer {
      .el-button {
        // font-size: 20px;
      }
      .upload {
        background-color: #fe846b;
        color: #ffffff;
      }
    }
  }
}
/deep/.ql-snow.ql-toolbar .ql-upload {
  // background: url("../../../assets/images/personal_img7.png");
  background-size: 16px 16px;
  background-position: center center;
  background-repeat: no-repeat;
}
</style>