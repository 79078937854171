  <!-- 首页侧边栏 -->
<template>
  <div class="GlobalSideBar-navList">
    <div class="GlobalSideBar-navItem" @click="goToMyTopic(2)">
      <i class="el-icon-user-solid"></i>
      <span class="GlobalSideBar-navText">我的动态</span>
      <span class="GlobalSideBar-navNumber">{{myTopicCount}}</span>
    </div>
    <div class="GlobalSideBar-navItem" @click="goToMyTopic(1)">
      <!-- <span class="iconfont icon-shoucang"></span> -->
       <i class="el-icon-star-on" style="font-size:16px;"></i>
      <span class="GlobalSideBar-navText">我的收藏</span>
      <span class="GlobalSideBar-navNumber">{{myCollectionCount}}</span>
    </div>
    <!-- <div class="GlobalSideBar-navItem">
      <span class="iconfont icon-bangzhuzhongxin"></span>
      <span class="GlobalSideBar-navText">帮助中心</span>
    </div> -->
  </div>
</template>
<script>
import { queryMyTopicCount } from "@/api/bbs";

export default {
  name: "global-side-bar",
  data() {
    return {
      // sectionList: [],
      user:{},
      myCollectionCount:0,
      myTopicCount:0
    };
  },
  mounted(){
    if(localStorage.getItem("userInfo")!=null)
    {
      this.user = JSON.parse(localStorage.getItem("userInfo"));
    }
    else
    {
      this.user.Id = 0;
    }
    this.getQueryMyTopicCount();
  },
  methods: {
    //进入详情页
    goToMyTopic(category) {
       if(this.user.Id == 0)
      {
        this.$message.error("请先登录");
        return false;
      }
       this.$router.push({
              path: "/bbs/myTopic",
              query: {category: category},
            });
    },
    async getQueryMyTopicCount() {
        const res = await queryMyTopicCount(this.user.Id);
        console.log(res);
        if (res.success == true) {
            this.myTopicCount = res.response.MyTopicCount;
            this.myCollectionCount = res.response.MyCollectionCount;
          }
    },
  }
};
</script>
<style lang='scss' scoped>
@import '../../assets/css/config.scss';
.GlobalSideBar-navList {
  width: 100%;
  padding: 8px 0;
  border-radius: 2px;
  background: #ffffff;
  font-size: 14px;
  color: $fontColor;
  .GlobalSideBar-navItem {
    padding: 0 20px;
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;
  }
  .GlobalSideBar-navText{
    flex:1;
    margin: 0 10px;
  }
  .GlobalSideBar-navNumber{
    padding: 6px 10px;
    border-radius: 2px;
    background: #f6f6f6;
    font-size: 12px;
  }
}
</style>

