<template>
  <div>
    {{ idCollection }}
    <div class="h5teacherInformation">
      <div class="teacher_contop">
        <img
          class="UserCover-image"
          src="../../../assets/images/bbsbackground.jpg"
          alt="用户封面"
          width="100%"
          height="100%"
        />
      </div>
      <div class="">
        <div class="teacherpic_box">
          <img :src="this.GLOBAL.hostUrl + user.HeadImg" alt />
          <div class="teacherinfo">
            <p>{{ user.RealName }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="H5Topstory-container">
      <div class="Topstory-mainColumn">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="postQueryAllTopicList"
          v-if="feedList.length > 0"
        >
          <div v-for="(item, index) in feedList" :key="index">
            <feed-item :feedList="item" :isDelete="false"></feed-item>
          </div>
        </van-list>
        <div v-if="feedList.length <= 0">
          <no-data></no-data>
        </div>
        <!-- <div class="Loading" v-show="isLoad">拼命加载中</div>
        <div class="Loading" v-show="isEnd">我是有底线的</div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { queryAllTopicList, querySectionList, queryStudentInfoById } from "@/api/bbs";
import FeedItem from "@/components/bbs/FeedItem.vue";
import GlobalSideBar from "@/components/bbs/GlobalSideBar.vue";
import Modal from "@/components/bbs/Modal.vue";
import AskModal from "@/components/bbs/AskModal.vue";
import noData from "@/components/noData";
import { mapGetters, mapActions } from "vuex";

let Load;
export default {
  name: "index",
  components: {
    FeedItem,
    GlobalSideBar,
    Modal,
    AskModal,
    noData
  },
  computed: {
    //监听词条
    ...mapGetters(["idCollection"])
  },
  provide () {
    return {
      finishDelete: this.finishDelete,
    };
  },
  watch: {
    idCollection (newValue) {
      if (newValue) {
        let that = this
        that.feedList = [];
        that.recPage = 1;
        that.isEnd = false;
        that.finished = false;
        that.postQueryAllTopicList();
      }
    },
    isAdd: function (newValue) {
      // this.getContent();
      this.postQueryAllTopicList();
    },
    searchList: function (newValue) {
      this.feedList = newValue;
      console.log("更新列表");
    },
    category (val, oldVal) {
      //普通的watch监听
      console.log("category: " + val, oldVal);
    },
    $route: {
      handler () {
        this.category = 1;
        this.feedList = [];
        this.recPage = 1;
        this.postQueryAllTopicList();
        //深度监听，同时也可监听到param参数变化
      },
      deep: true,
    },
  },
  data () {
    return {
      feedList: [],
      activeName: "",
      followList: [],
      isLoad: false,
      recPage: 1, //推荐页面page
      // attPage: 1, //关注页面page
      pageSize: 10,
      loading: false,
      finished: false,
      isEnd: false,
      user: {},
      sectionList: [],
      sectionId: 1,
      showLoginModal: false,
      showModal: false,
      disabled: true,
      askDetail: "", //提问细节
      askTip: "",
      askTitle: "", //提问问题
      loginUser: "", //登录用户
      loginPass: "", //注册密码
      category: 1,
    };
  },
  created () {
    var that = this;
    window["callByAndroidParam"] = function (jsonStr) {
      if (jsonStr != "") {
        that.user.Id = jsonStr.data;
        that.getQueryStudentInfoById();
      } else {
        this.$message.error("登录失败");
      }
    };
  },
  mounted () {
    // this.getContent();
    if (localStorage.getItem("userInfo") != null) {
      this.user = JSON.parse(localStorage.getItem("userInfo"));
    } else {
      this.user.Id = 0;
      this.callApp();
    }
    this.category = this.$route.query.category;
    this.postQueryAllTopicList();
    // window.addEventListener( 'scroll', this.listenScroll);
    window.userIDResultByIos = this.userIDResultByIos;
    this.setIdCollection("")
  },
  methods: {
    ...mapActions(["setIdCollection"]),
    userIDResultByIos (jsonStr) {
      if (jsonStr != "") {
        this.user.Id = jsonStr.data;
        this.getQueryUserInfo();
      } else {
        this.$message.error("登录失败");
      }
    },
    callApp () {
      let u = navigator.userAgent;
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (window.android != null && typeof window.android != "undefined") {
        //可以传递自定义数据，比如json
        window.android.callAndroid(JSON.stringify({ action: "getUserID" }));
      } else if (isiOS) {
        window.webkit.messageHandlers.callIosGetUserID.postMessage(JSON.stringify({ action: "callIosGetUserID" }));
      }
    },
    //根据Id获取学生信息
    async getQueryStudentInfoById () {
      const res = await queryStudentInfoById(this.user.Id);
      this.user = res.response;
      console.log(this.user);
      localStorage.setItem("userInfo", JSON.stringify(this.user));
    },
    showLoginModalFn () {
      this.showLoginModal = true;
    },
    finishDelete (id) {
      this.feedList.some((item, i) => {
        if (item.Id == id) {
          this.feedList.splice(i, 1); //在数组的some方法中，如果return true，就会立即终止这个数组的后续循环
          return true;
        }
      });
    },
    showAskModal () {
      this.showModal = true;
      console.log(this.showModal);
    },
    // 列表数据
    async postQueryAllTopicList () {
      let parm = {};
      parm.pageIndex = this.recPage;
      parm.pageSize = this.pageSize;
      parm.IsAll = false;
      parm.IsMyCollection = true;
      parm.UserId = this.user.Id;
      const res = await queryAllTopicList(parm);
      this.feedList = this.feedList.concat(res.data);
      this.$store.dispatch("setIsAdd", false);
      this.pageIndex++;
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.feedList.length >= res.dataCount) {
        this.finished = true;
      }
      // this.isLoad = false;
      // if (res.data.length == 0) {
      //   this.isEnd = true;
      // }
    },
    //滚动
    listenScroll () {
      let that = this;
      let ele = document.documentElement;
      let scr = ele.scrollTop; // 向上滚动的那一部分高度
      let clientHeight = ele.clientHeight; // 屏幕高度也就是当前设备静态下你所看到的视觉高度
      let scrHeight = ele.scrollHeight; // 整个网页的实际高度，兼容Pc端
      if (scr + clientHeight + 5 >= scrHeight) {
        // console.log(that.isEnd);
        // if (that.isLoad == false||this.isEnd == false) {
        if (that.isEnd == false) {
          // that.isLoad = true;
          that.recPage++;
          that.postQueryAllTopicList();
        }
      }
    },
    changeItem: function (tab, event) {
      this.category = tab.$attrs.category;
      this.feedList = [];
      this.recPage = 1;
      this.postQueryAllTopicList();
    },
    //发表问题
    publishQues () {
      let params = {
        title: this.askTitle,
        content: this.askDetail,
      };
      this.axios.post("/question/add", params).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: "提问成功",
            type: "success",
          });
          this.showModal = false;
          this.askTitle = "";
          this.askDetail = "";
          this.$store.dispatch("setIsAdd", true);
        }
      });
    },
    //监听标题文字数量
    listenChange () {
      if (this.askTitle.length < 4) {
        this.askTip = "至少输入4个字";
        this.disabled = true;
      } else {
        this.askTip = "";
        this.disabled = false;
      }
    },
  },
  beforeDestroy () {
    // window.removeEventListener('scroll', this.listenScroll);
  },
};
</script>
<style lang="scss" >
@import "@/assets/css/config";
.H5Topstory-container {
  display: flex;
  width: 100%;
  // padding: 0 16px;
  margin: 10px auto;
  height: 100%;
}
.Topstory-mainColumn {
  position: relative;
  width: 100%;
  height: 100%;
  .el-tabs__header {
    background: #ffffff;
    padding: 10px 20px;
    margin: 0;
    border-bottom: 1px solid #ebebeb;
  }
  .el-tabs__item {
    font-size: 16px;
  }
  .el-tabs__item.is-active {
    color: $mainColor;
  }
  .el-tabs__nav-wrap::after {
    background: transparent;
  }
  .el-tabs__active-bar {
    height: 0;
  }
}
.GlobalSideBar {
  margin-left: 10px;
  flex: 1;
}
.Loading {
  height: 50px;
  text-align: center;
  color: $mainColor;
}
.AskButton {
  color: #fff;
  background-color: #0084ff;
  width: 90px;
  padding: 8px 16px;
  font-size: 14px;
  margin-top: 10px;
  align-self: flex-end;
  &:disabled {
    opacity: 0.5;
  }
  position: absolute;
  top: 4px;
  right: 2.6%;
  border-radius: 999px;
  width: 70px;
}

.h5teacherInformation {
  //display: flex;
  // margin-top: 15px;
  background-color: white;
  width: 100%;
  // padding: 0 16px;
  // margin: 10px auto;
  height: 100%;
  .teacher_contop {
    height: 180px;
    z-index: 0;
    background: #76a6c8 url(../../../assets/images/innertopbg.jpg) no-repeat 50%
      0;
  }
  .teacherpic_box {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    margin-top: -50px;
    img {
      border-radius: 8px;
      border: 4px solid #fff;
      // border: 6px solid #fff;
      width: 80px;
      height: 80px;
      // border-radius: 100%;
      display: block;
      overflow: hidden;
      text-align: center;
      margin-left: 15px;
      margin-right: 15px;
      background: #fff;
    }
    .teacherinfo {
      p {
        &:nth-child(1) {
          font-size: 15px;
          height: 24px;
          line-height: 24px;
          color: #fff;
        }
      }
    }
  }
}
.TopstoryItem img {
  max-width: 100%;
  height: 100px;
}
.CommentReply-button {
  margin-right: 10px;
}
</style>

